<template>
  <v-container>
    <one-breadcrumbs
      v-if="breadcrumbs"
      :breadcrumbs="breadcrumbs"
    />
    <slot />
    <v-layout
      row
      layout
    >
      <v-flex
        xs12
        md8
        offset-md2
      >
        <v-flex
          d-flex
          xs12
        >
          <v-container grid-list-xl>
            <v-layout
              justify-space-around
              row
              wrap
            >
              <v-flex
                v-for="(item, i) in kitsItems"
                :key="i"
                d-flex
                xs12
                sm6
              >
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    :color="color(i)"
                    :to="item.path"
                    :class="`elevation-${hover ? 13 : 2}`"
                    ripple
                  >
                    <template v-if="item.image">
                      <v-img
                        :src="item.image"
                        class="white--text"
                      >
                        <v-card-title primary-title>
                          <div>
                            <span class="headline">{{ item.title }}</span><br>
                            <span>{{ item.desc }}</span>
                          </div>
                        </v-card-title>
                      </v-img>
                    </template>
                    <template v-else>
                      <v-card-text>
                        <v-icon
                          dark
                          x-large
                        >
                          {{ item.icon }}
                        </v-icon>
                      </v-card-text>
                      <v-card-title primary-title>
                        <div>
                          <span class="headline">{{ item.title }}</span><br>
                          <span>{{ item.desc }}</span>
                        </div>
                      </v-card-title>
                    </template>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {
    oneBreadcrumbs: () => import(/* webpackChunkName: "breadcrumbs" */ '@/components/Breadcrumbs'),
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    kitsItems: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      colors: [
        'red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green',
        'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey',
      ],
      colorIndex: 0,
    }
  },
  created: function () {
    let currentIndex = this.colors.length
    let color
    let randomIndex

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex--)
      color = this.colors[currentIndex]
      this.colors[currentIndex] = this.colors[randomIndex]
      this.colors[randomIndex] = color
    }
  },
  methods: {
    color (index) {
      return this.colors[index] + ' darken-2'
    },
  },
}
</script>
